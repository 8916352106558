<template>
	<div class="progress">
		<div class="column-1">
			<div class="progress-stages">
				<div class="progress-stages__title">Прогресс</div>
				<div class="progress-stages__description">
					Здесь отображается Ваш статус в зависимости от совершенных поездок. За
					каждый статус вы получаете фиксированную скидку на последующие
					поездки.
				</div>
				<ul class="progress-stages__stages">
					<li class="stage" v-for="item in menu.statuses" :key="item.link">
						<a
							href="#"
							@click.prevent="select(item.id)"
							:class="[
								'stage__link',
								{ active: current === item.id },
								{ disabled: item.id > active.status_id + 1 }
							]"
						>
							<div class="stage__img">
								<img
									class="stage__img"
									:src="progress_icons[item.id]"
									:alt="item.title"
									width="60"
									height="60"
								/>
							</div>
							<div class="stage__name">
								{{ item.title }}
							</div>
							<div
								:class="[
									'stage__complete',
									{ 'active-complete': item.id <= active.status_id }
								]"
							/>
							<div class="stage__arrow">
								<!-- <tk-icon type="right" /> -->
							</div>
						</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="column-2">
			<div v-if="status" class="selected-stage">
				<div class="selected-stage__title">{{ status.title }}</div>
				<div class="selected-stage__content">
					<div class="stage">
						<div class="stage__wrap">
							<div class="stage__img">
								<img :src="icon" :alt="status.title" decoding="async"/>
							</div>
							<div class="stage__name">{{ status.title }}</div>
						</div>

						<div v-if="active.status_id >= status.id" class="stage__text">
							Вы уже обладаете званием “{{ status.title }}”
						</div>

						<div v-else class="stage__progress">
							<p class="title">Количество дней</p>
							<!-- <el-progress
								class="days"
								:percentage="active.freeze_day"
								:show-text="false"
								:stroke-width="8"
							/> -->
							<div class="stage-count stage-count__days">
								<span>0</span>
								<span>10</span>
							</div>
							<div class="title title__reviews">
								<p>Количество отзывов</p>
								<!-- <div class="info-img" @click="showReviewsInfoModal">
									<img
										src="@/assets/images/svg/info-black.svg"
										alt="Иконка информации"
									/>
								</div> -->
							</div>

							<!-- <el-progress
								type="line"
								class="reviews"
								:percentage="active.count_comments"
								:show-text="false"
								:stroke-width="8"
							/> -->
							<div class="stage-count stage-count__reviews">
								<span>0</span>
								<span>100</span>
							</div>
						</div>

						<div class="comission">
							<div class="comission-block">
								<div class="comission-block__title">текущая</div>
								<div class="comission-block__content">
									комиссия <span>{{ commission }}%</span>
								</div>
							</div>
							<div class="comission-arrow"></div>
							<div class="comission-block">
								<div class="comission-block__title">будущая</div>
								<div class="comission-block__content">
									комиссия <span>{{ nextCommission }}%</span>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="selected-stage__description">
					Для повышения статуса нужно совершить необходимое количество поездок.
					Размер Вашей скидки рассчитывается автоматически во время оплаты
					бронирования.
				</div>
			</div>

			<!-- All pages progress -->
			<div class="progress-about">
				<h4 class="progress-about__title">О статусе прогресса аккаунта</h4>
				<p class="progress-about__text">Это раздел прогресса Вашего профиля.</p>
				<p class="progress-about__text">
					В соответствии с этим статусом, наш сервис взимает комиссию в виде
					некоторого процента (%) от каждого вашего бронирования.
				</p>
				<p class="progress-about__text">
					Комиссия начисляется только от суммарной стоимости всех ночей в вашем
					бронировании (без учёта дополнительных услуг).
				</p>
				<p class="progress-about__text">
					В случае отмены бронирования после подтверждения заявки хозяином
					жилья, <span>комиссия не возвращается</span>.
				</p>
				<p class="progress-about__text">
					Чем выше статус, тем меньше комиссия Теремка с каждого вашего
					бронирования.
				</p>
			</div>
			<!-- /All pages progress -->
		</div>
	</div>
</template>

<script>
import { CLIENT } from '@/constants/store/progress/actions'

import progress_icons from '@/constants/progress'

const links = {
	1: '/profile/progress/guest/homebody',
	2: '/profile/progress/guest/rare',
	3: '/profile/progress/guest/welcome',
	4: '/profile/progress/guest/experience',
	5: '/profile/progress/guest/citizen'
}

export default {
	name: 'ProgressGuest',
	data() {
		return {
			current: 1
		}
	},
	async beforeMount() {
		await this.$store.dispatch(CLIENT)
	},
	computed: {
		links() {
			return links
		},
		progress_icons() {
			return progress_icons
		},
		progress() {
			return this.$store.state.progress
		},
		menu() {
			return this.progress.client
		},
		active() {
			return this.progress.client.user_data
		},
		statuses() {
			return this.progress.client.statuses
		},
		status() {
			return this.statuses
				? this.statuses.find((itm) => itm.id === this.current)
				: null
		},
		currentIdx() {
			return this.active
				? this.statuses.findIndex((itm) => itm.id === this.active.status_id)
				: 0
		},
		commission() {
			return this.statuses ? this.statuses[this.currentIdx]?.commission : 0
		},
		nextCommission() {
			return this.statuses ? this.statuses[this.currentIdx + 1]?.commission : 0
		},
		icon() {
			return this.active ? this.progress_icons[this.active.status_id] : null
		}
	},
	methods: {
		select(id) {
			if (id <= this.active.status_id + 1) {
				this.current = id
			}
		}
	}
}
</script>
