<template>
	<keep-alive>
		<component :is="$store.getters.mode" />
	</keep-alive>
</template>

<script>
import mods from '@/constants/mods'

import guest from './Guest'
import master from './Master'

export default {
	name: 'Progress',
	components: {
		[mods.GUEST]: guest,
		[mods.MASTER]: master
	}
}
</script>

<style lang="sass">
.progress
	display: flex
	justify-content: space-between
	.column-1
		width: 420px
	.column-2
		width: 420px
	.progress-stages
		&__title
			font-weight: bold
			font-size: 24px
			line-height: 24px
			color: #3B3F47
			margin-bottom: 30px
		&__description
			font-size: 14px
			line-height: 16px
			color: #CBCBCB
			padding-right: 33px
			margin-bottom: 30px
		&__stages
			display: flex
			flex-direction: column
			list-style: none
			padding: 0
			margin: 0
			.stage
				box-sizing: border-box

				&:not(:last-child)
					margin-bottom: 15px

				&__link
					display: flex
					justify-content: space-between
					align-items: center
					height: 70px
					padding: 5px 15px 5px 15px
					box-shadow: 0px 5px 17px -6px lightgrey
					background: #FFFFFF
					border-radius: 12px
					cursor: pointer
					text-decoration: none
					transition: 0.1s linear
					&.disabled
						opacity: 0.6
						pointer-events: none

					&:hover:enabled
						background: #1AC386
						color: #ffffff

					&:hover:enabled
						& .stage__name
							color: #ffffff
						& .iconm
							color: #ffffff
						.stage__complete::before
							background: url('~@/assets/images/svg/complete-hover.svg') center no-repeat

					&.active
						background: #1AC386
						.stage__name, .stage__complete
							color: #ffffff
						.iconm
							color: #ffffff
						.stage__complete::before
							background: url('~@/assets/images/svg/complete-hover.svg') center no-repeat
					// завершенная стадия прогресса
					&.complete
						.stage__complete
							visibility: hidden
							&:before
								visibility: visible
					// сдатия в прогрессе
					&.inprogress
						.stage__complete
							visibility: visible
							&:before
								visibility: hidden

				&__name
					width: 270px
					font-weight: 600
					font-size: 14px
					line-height: 14px
					color: #3B3F47
				&__complete
					position: relative
					padding-top: 2px
					width: 40px
					height: 22px
					margin-right: 15px
					font-weight: 600
					font-size: 14px
					line-height: 14px
					visibility: hidden
					&.active-complete
						visibility: visible
					&:before
						content: ''
						position: absolute
						width: 40px
						height: 22px
						background: url('~@/assets/images/svg/complete.svg') center no-repeat
				.iconm-right
					font-size: 13px
				// активный элемент прогресса
	&-about
		&__title
			font-weight: bold
			font-size: 18px
			line-height: 21px
			margin-bottom: 15px
		&__text
			font-size: 17px
			line-height: 22px
			span
				text-decoration: underline
// 1080
@media screen and (max-width: 1440px)
	.progress
		.column-1
			margin-right: 80px
			width: 350px
		.column-2
			width: 350px

// 996
@media screen and (max-width: 1180px)
	.progress
		.column-1
			margin-right: 60px
			width: 330px
		.column-2
			width: 330px
		.progress-stages
			&__description
				padding-right: 0

// 900
@media screen and (max-width: 1024px)
	.progress
		.column-1
			margin-right: 40px
			width: 300px
		.column-2
			width: 300px

// 768
@media screen and (max-width: 912px)
	.progress
		.column-1
			margin-right: 20px
			width: 50%
		.column-2
			width: 50%

// 540
@media screen and (max-width: 1000px)
	.progress
		flex-wrap: wrap
		justify-content: center
		width: 100%
		.column-1
			margin-right: 0
			margin-bottom: 30px
			width: 100%
		.column-2
			width: 100%
		.progress-stages
			&__description
				padding-right: 0
			.stage__name
				width: 320px
		&-about
			text-align: justify

// selected stage

.selected-stage,
.selected-stage-wip
	display: block
	&__title
		font-weight: bold
		font-size: 24px
		line-height: 24px
		color: #3B3F47
		margin-bottom: 23px
	&__content
		.stage
			position: relative
			box-shadow: 0px 5px 17px -6px lightgrey
			border-radius: 12px
			padding: 5px 20px 15px 20px
			margin-bottom: 23px
			width: 100%
			&__wrap
				display: flex
				align-items: center
				margin-bottom: 30px
			&__img
				margin-right: 10px
				width: 60px
				height: 60px
				img
					width: 100%
					height: 100%
			&__name
				font-weight: 600
				font-size: 16px
				line-height: 16px
				color: #3B3F47
			&__text
				font-size: 14px
				line-height: 14px
				color: #3B3F47
				margin-bottom: 20px
				&.congratulation
					font-weight: bold
					margin-bottom: 10px
				span
					margin-left: 2px
					margin-right: 2px
			&__progress
				.title
					font-size: 14px
					line-height: 14px
					margin-bottom: 15px
					&__reviews
						display: flex
						justify-content: space-between
						align-items: center
						p
							margin-bottom: 0
						.info-img
							cursor: pointer
				.days, .reviews
					margin-bottom: 5px
				.stage-count
					display: flex
					justify-content: space-between
					font-weight: 600
					font-size: 12px
					line-height: 14px
					&__days
						margin-bottom: 20px
			.comission
				display: flex
				justify-content: center
				align-items: center
				&-block
					width: 105px
					&__title
						font-size: 8px
						line-height: 12px
						color: #CBCBCB
					&__content
						font-weight: 600
						font-size: 12px
						line-height: 12px
						display: flex
						justify-content: space-between
						align-items: center
						span
							color: #1AC386
						&:before
							content: url("~@/assets/images/svg/percentage.svg")
							background:  center no-repeat
				&-arrow
					width: 30px
					height: 20px
					background: url("~@/assets/images/svg/arrow-long-grey.svg") center no-repeat
					background-position: center 90%
	&__description
		padding-right: 33px
		font-size: 14px
		line-height: 16px
		color: #CBCBCB
		margin-bottom: 30px

@media screen and (max-width: 1180px)
	.selected-stage,
	.selected-stage-wip
		&__description
			padding-right: 0
		.stage
			&__wrap
				justify-content: flex-start
				align-items: center
			&__text
				text-align: center

@media screen and (max-width: 912px)
	.selected-stage,
	.selected-stage-wip
		.stage
			&__wrap
			.comission
				position: static
				flex-direction: row
				&-arrow
					transform: unset
					width: 30px
					height: 25px
					background-position: center 80%

@media screen and (max-width: 800px)
	.selected-stage,
	.selected-stage-wip
		display: flex
		flex-direction: column
		align-items: center
		.stage
			padding-bottom: 13px
			&__wrap
				flex-direction: column
			&__text
				display: flex
				justify-content: center
				align-items: center
				line-height: 22px

		&__content
			width: 100%
		&__description
			text-align: center
			padding-right: 0
</style>
